import React from 'react';
import { l } from '../../../app';
import { TranslatableText } from '../../../context/lang';
import { Title } from '../../title/title';
import { Wrapper } from '../../wrapper/wrapper';
import './aboutus.scss';

const AboutUsCards = ({cards}) => {
    return (
        <div className='about-us__cards'>
            {cards.map((card, i) => (
                <div key={i} className='about-us__cards-item'>
                    <div className='about-us__cards-item-block'>
                        <div className='about-us__cards-item-block-number'>{card.number}</div>
                        <div className='about-us__cards-item-block-text'>{card.text}</div>
                    </div>
                    <div className='about-us__cards-item-subtitle'>{card.subtitle}</div>
                </div>
            ))}
        </div>
    );
}

export const AboutUs = () => {
    return (
        <div id='about-us' className='about-us'>
            <Wrapper styleClass="about-us__wrapper">
                <Title name={l === 'en' ? `About us` : `О Нас`} />
                <div className='about-us__subtitle'>
                <TranslatableText
                    dictionary={{
                        en: "A young, successful and promising team of developers",
                        ru: "Молодая, успешная и перспективная команда разработчиков",
                    }} />
                </div>
                <AboutUsCards cards={[
                    {
                        number: '3',
                        text: l === 'en' ? `years` : `года`,
                        subtitle: l === 'en' ? `We successfully complete projects of any complexity` : `Успешно реализуем проекты любой сложности`
                    },
                    {
                        number: '9+',
                        text: l === 'en' ? `projects` : `проектов`,
                        subtitle: l === 'en' ? `Finished projects, ready-to-use` : `Готовые проекты, готовые к использованию`
                    },
                    {
                        number: '3',
                        text: l === 'en' ? `startups` : `стартапов`,
                        subtitle:  l === 'en' ? `Interesting and innovative solutions` : `Интересные и инновационные решения`
                    },
                ]} />
            </Wrapper>
        </div>
    );
}