import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { l } from '../../app';
import { TranslatableText } from '../../context/lang';
import { Wrapper } from '../wrapper/wrapper';
import './footes.scss';

export const FooterBottom = () => {
    return (
        <div className='footer__bottom'>
            <div className='footer__bottom-gray' />
            <div className='footer__bottom-black'>
                ООО Драйвери ИНН 2352055816
            </div>
            <div className='footer__bottom-gray' />
        </div>
    );
}

export const Footer = () => {
    return (
        <Wrapper>
            <div className='footer'>
                <div className='footer__links'>
                    <HashLink to={l === 'en' ? `/en` : `/`} className='footer__links-logo link'>Drivery</HashLink>
                    <div className='footer__links-link-block'>
                        <div className='footer__links-link-block-item'>
                            <div className='footer__links-link-block-item-title'>
                            <TranslatableText
                                dictionary={{
                                    en: "Links",
                                    ru: "Ссылки",
                                }} />
                            </div>
                            <HashLink to={l === 'en' ? `/en/#about-us` : `/#about-us`} className='footer__links-link-block-item-link'>
                            <TranslatableText
                                dictionary={{
                                    en: "About us",
                                    ru: "О Нас",
                                }} />
                            </HashLink>
                            <HashLink to={l === 'en' ? `/en/#our-tools` : `/#our-tools`} className='footer__links-link-block-item-link'>
                            <TranslatableText
                                dictionary={{
                                    en: "Our tools",
                                    ru: "Наши инструменты",
                                }} />
                            </HashLink>
                            <HashLink to={l === 'en' ? `/en/#contact` : `/#contact`} className='footer__links-link-block-item-link'>
                            <TranslatableText
                                dictionary={{
                                    en: "Contact",
                                    ru: "Контакты",
                                }} />
                            </HashLink>
                            <HashLink to={l === 'en' ? `/en/privacy` : `/privacy`} className='footer__links-link-block-item-link'>
                            <TranslatableText
                                dictionary={{
                                    en: "Privacy Policy",
                                    ru: "Политика Конфиденциальности",
                                }} />
                            </HashLink>
                        </div>
                        <div className='footer__links-link-block-item'>
                            <div className='footer__links-link-block-item-title'>
                            <TranslatableText
                                dictionary={{
                                    en: "Contacts",
                                    ru: "Контакты",
                                }} />
                            </div>
                            {l === 'en' ? <div className='footer__links-link-block-item-link'>LLC Drivery</div> : <div className='footer__links-link-block-item-link'>ООО Драйвери</div>}
                            <a href="mailto:info@drivery.org" className='footer__links-link-block-item-link'>info@drivery.org</a>
                        </div>
                        {/* <div className='footer__links-link-block-item'>
                            <div className='footer__links-link-block-item-title'>Drivery</div>
                            <HashLink to="#" className='footer__links-link-block-item-link'>Public Offer</HashLink>
                        </div> */}
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}