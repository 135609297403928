import React from 'react';
import './ourworks.scss'
import Slider from "react-slick";
import { Title } from '../../title/title';
import { l } from '../../../app';
import { Link } from 'react-router-dom';
import { works } from './worksdata';
import { useWindowDimensions } from '../../../app';

export const SliderBlock = ({slides}) => {
    const { width } = useWindowDimensions();

    var settings = {
        nextArrow: <img src={require('../../../assets/our-works/right-arrow.svg').default} alt="arrow" />,
        prevArrow: <img src={require('../../../assets/our-works/left-arrow.svg').default} alt="arrow" />,
        autoplay: true,
        draggable: true,
        autoplaySpeed: 3500,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: width <= 800 ? 1 : 3,
        slidesToScroll: 1
      };

    return (
        <Slider {...settings}>
            {slides.map(slide => {
            const { id, img, icon, title, subtitle, linkName } = slide;

            return (
                <Link to={`/page-work/${linkName}`} key={id} className='our-works__item link'>
                    <img src={require(`../../../assets/our-works/${img}.webp`)} className="our-works__item-img" alt='our-work_image'/>
                    <div className='our-works__item-name'>
                        <img src={require(`../../../assets/our-works/icons/${icon}.svg`)} className="our-works__item-name-img" alt='icon'/>
                        <div className='our-works__item-name-title'>{title}</div>
                    </div>
                    <div className='our-works__item-subtitle'>{subtitle}</div>
                    <Link to={`/page-work/${linkName}`} className='our-works__item-btn'>Подробнее <img src={require('../../../assets/our-works/chevron-right.svg').default} className='our-works__item-btn-link' alt='icon' /></Link>
                </Link>
            )})}
        </Slider>
    );
}

export const OurWorks = () => {
    return (
        <div id='our-works' className='our-works'>
            <Title name={l === 'en' ? `Our Projects` : `Наши проекты`} styleClass="our-works__title" />
            <SliderBlock slides={works}/>
        </div>
    );
}