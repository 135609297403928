import React from 'react';
// import { Button } from '../../components/button/button';
import './notfound.scss';

export const NotFound = () => {
    return (
        <div className='not-found'>
            {/* <img className='not-found__img' src={require("../../assets/images/404.webp")} alt='not-found' /> */}
            <img className='not-found__lang' src={require("../../assets/images/lang.webp")} alt='langvid' />
            {/* <Button link="/" name="Back to Homepage" /> */}
        </div>
    );
}