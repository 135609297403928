import React, { useState, useRef, useCallback } from 'react';
import { Button } from '../../button/button';
import { Title } from '../../title/title';
import { Wrapper } from '../../wrapper/wrapper';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Modal } from '../../modal/modal';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import 'react-notifications/lib/notifications.css';
import './contact.scss';
import { l } from '../../../app';
import { TranslatableText } from '../../../context/lang';

export const Contact = () => {
    const [numberPhoneCard, setNumberPhoneCard] = useState('');
    const [name, setName] = useState('');
    const [token, setToken] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const captchaRef = useRef(null);
    const inputRefPhoneCard = useRef(null);
    const inputRefName = useRef(null);

    // info@drivery.org
    const SendMail = (name, numberPhoneCard) => {
        window.Email.send({
            SecureToken : "43a22622-b674-4744-99f2-c67be48028b8",
            To : 'info@drivery.org',
            From : "tgmall@tgmall.ru",
            Subject : "🟢 Drivery (Заявка через форму Drivery)",
            Body : `
            <h1 style="text-align: center">ООО Драйвери</h1>
            <p style="text-align: center">Заявка на сайте <a href="https://drivery.org/">drivery.org</a></p>
            <table style="border-collapse: collapse; width: 100%;">
            <tr>
              <td style="padding: 10px; border: #e9ecef solid 1px;">Имя:</td>
              <td style="padding: 10px; border: #e9ecef solid 1px;"><strong>${name}</strong></td>
            </tr>
            <tr>
              <td style="padding: 10px; border: #e9ecef solid 1px;">Номер телефона или карты:</td>
              <td style="padding: 10px; border: #e9ecef solid 1px;"><a href="tel:${numberPhoneCard}">${numberPhoneCard}</a></td>
            </tr>
          </table>
          `
        }).then(
            message => console.log(message)
        );
    }

    // FUNCTIONS
    const onExpire = () => {
        console.log("hCaptcha Token Expired");
    };
    
    const onError = (err) => {
        console.log(`hCaptcha Error: ${err}`);
    };

    const createNotification = (type) => {
        switch (type) {
          case 'phone':
            NotificationManager.info(l === 'en' ? `The number is entered incorrectly!` : `Неправильно введен номер!`);
            break;
          case 'success':
            NotificationManager.success(l === 'en' ? `The form has been sent!` : `Форма отправлена!`);
            break;
          case 'nameLimit':
            NotificationManager.info(l === 'en' ? `The name must not exceed more than 35 characters!` : `Имя не должно превышать больше 35 символов!`);
            break;
          case 'name':
            NotificationManager.info(l === 'en' ? `Name entered incorrectly!` : `Неправильно введено Имя!`);
            break;
          case 'checkForm':
            NotificationManager.info(l === 'en' ? `Fill in your name and phone/mail number!` : `Заполните имя и телефон/почту!`);
            break;
        }
    };

    var re = /[^[A-Za-zА-Яа-яёЁ]+(?:[-'\s][A-Za-zА-Яа-яёЁ]+)*$]/;
    const checkName = (name) => {
        if (name.length > 35) {
            createNotification('nameLimit');
            return false;
        }
        else if (re.test(name)) {
            createNotification('name');
            return false;
        }
        return true;
    }

    const checkAllInputs = () => {
        if (numberPhoneCard !== "" && name !== "" && token !== null) {
            return true;
        }
        return false;
    }

    const checkInputs = () => {
        if (numberPhoneCard !== "" && name !== "") {
            return true;
        }
        return false;
    }

    const submit = (name, numberPhoneCard) => {
        if (numberPhoneCard && checkName(name) && token !== null) {
            SendMail(name, numberPhoneCard);
            setNumberPhoneCard('');
            setName('');
            setToken(null);
            inputRefPhoneCard.current.value = "";
            inputRefName.current.value = "";
            captchaRef.current.resetCaptcha();
            setShowModal(true);
            return true;
        }
        return false;
    }

    // CALLBACKS
    const numberPhoneCardChangeCallback = useCallback(
        (e) => {
            setNumberPhoneCard(e.target.value);
        }, []
    );

    const nameChangeCallback = useCallback(
        (e) => {
            setName(e.target.value);
        }, []
    );

    return (
        <div id='contact' className='about-us contact'>
            {showModal ? <Modal onPress={() => setShowModal(!showModal)} /> : null}
            <Wrapper styleClass="about-us__wrapper">
                <Title name={l === 'en' ? `Contact` : `Контакты`} />
                <div className='contact__blocks'>
                    <div className='contact__blocks-text'>
                        <div className='contact__blocks-text-paragraph'>
                        <TranslatableText
                            dictionary={{
                                en: "You have an idea to create a",
                                ru: "У вас есть идея создать ",
                            }} />
                            <br/>
                            <span className='contact__blocks-text-paragraph--border'>
                            <TranslatableText
                            dictionary={{
                                en: "Cool Startup,",
                                ru: "Крутой стартап,",
                            }} />
                            </span>
                            <br/>
                            <TranslatableText
                            dictionary={{
                                en: "but don't know where to start?",
                                ru: "но не знаете с чего начать?",
                            }} />
                            <br/>
                            <TranslatableText
                            dictionary={{
                                en: "We're",
                                ru: "Мы здесь,",
                            }} />
                            <span className='contact__blocks-text-paragraph--border'>
                            <TranslatableText
                            dictionary={{
                                en: "here to help!",
                                ru: "чтобы помочь!",
                            }} />
                            </span>
                        </div>
                        {/* <div className='contact__blocks-text-contacts'>
                            <a className='contact__blocks-text-contacts-link link' href='tel:+491602185181'><img className='contact__blocks-text-contacts-link-icon' src={require('../../../assets/icons/phone.svg').default} alt=''/>+491602185181</a>
                            <a className='contact__blocks-text-contacts-link link' href='mailto:info@drivery.org'><img className='contact__blocks-text-contacts-link-icon' src={require('../../../assets/icons/mail.svg').default} alt=''/>info@drivery.org</a>
                        </div> */}
                    </div>
                    <form className='contact__blocks-form'>
                        <div className='contact__blocks-form-title contact__blocks-form-title--header'>
                        <TranslatableText
                            dictionary={{
                                en: "Send your inquiry",
                                ru: "Отправить запрос",
                            }} />
                        </div>
                        <div className='contact__blocks-form-title'>
                        <TranslatableText
                            dictionary={{
                                en: "Name",
                                ru: "Имя",
                            }} />
                        </div>
                        <input ref={inputRefName} onChange={nameChangeCallback} className='contact__blocks-form-input' placeholder={l === 'en' ? `For example: John Doe` : `Например: Иван Иванов`} />
                        <div className='contact__blocks-form-title'>
                        <TranslatableText
                            dictionary={{
                                en: "Your contact",
                                ru: "Ваши контакты",
                            }} />
                        </div>
                        <input style={{marginBottom: '20px'}} ref={inputRefPhoneCard} onChange={numberPhoneCardChangeCallback} className='contact__blocks-form-input' placeholder={l === 'en' ? `mail / phone` : `почта / телефон`} />
                        {checkInputs() ?
                        <HCaptcha
                            sitekey="645b96a6-e290-4b84-b0ed-0b5d9834a34a"
                            onVerify={setToken}
                            ref={captchaRef}
                            // theme={'dark'}
                            onError={onError}
                            onExpire={onExpire}
                        /> : null}
                        {checkAllInputs() ?
                        <Button onClick={() => submit(name, numberPhoneCard)} styleClass="contact__blocks-form-btn" name={l === 'en' ? `Send` : `Отправить`} />
                        :
                        <Button onClick={() => createNotification("checkForm")} styleClass="contact__blocks-form-btn disabled" name={l === 'en' ? `Send` : `Отправить`} />
                        }
                    </form>
                </div>
                <NotificationContainer/>
            </Wrapper>
        </div>
    );
}