import React, { useState } from 'react';
import { Wrapper } from '../wrapper/wrapper';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './header.scss';
import { useWindowDimensions } from '../../app';
import { l } from '../../app';
import { Bmenu } from '../bmenu/bmenu';
import { TranslatableText } from '../../context/lang';

export const Header = () => {
    const { width } = useWindowDimensions();
    const [showBmenu, setShowBmenu] = useState(false);

    const replaceURL = () => {
        setTimeout(() => {
            window.location.reload();
        }, 0);
    }

    return (
        <Wrapper>
            <div className='header'>
                <HashLink to={l === 'ru' ? `/` : `/en`} className='header__logo link'>Drivery</HashLink>
                <div className={width <= 425 ? 'flex flex-row-reverse' : 'flex'}>
                    {width <= 425 ?
                    <>
                    {showBmenu ?
                    <img onClick={() => setShowBmenu(!showBmenu)} className='header__border' src={require('../../assets/icons/Close.svg').default} alt="" />
                    :
                    <img onClick={() => setShowBmenu(!showBmenu)} className='header__border' src={require('../../assets/icons/burger.svg').default} alt="" />}
                    </> 
                    :
                    <div className='header__links'>
                        <HashLink to={l === 'en' ? `/en/#about-us` : `/#about-us`} className='header__links-item link'>
                            <TranslatableText
                                dictionary={{
                                    en: "About us",
                                    ru: "О Нас",
                                }} />
                        </HashLink>
                        <HashLink to={l === 'en' ? `/en/#our-tools` : `/#our-tools`} className='header__links-item link'>
                        <TranslatableText
                            dictionary={{
                                en: "Our tools",
                                ru: "Наши инструменты",
                            }} />
                        </HashLink>
                        <HashLink to={l === 'en' ? `/en/#contact` : `/#contact`} className='header__links-item link'>
                        <TranslatableText
                            dictionary={{
                                en: "Contact",
                                ru: "Контакты",
                            }} />
                        </HashLink>
                        {l === 'en' ? null : 
                        <HashLink to={l === 'en' ? `` : `/#our-works`} className='header__links-item link'>
                        <TranslatableText
                            dictionary={{
                                en: "Our Works",
                                ru: "Наши проекты",
                            }} />
                        </HashLink>
                        }
                    </div>}
                    <Link to={l === 'en' ? `/` : `/en`} onClick={replaceURL} className='header__call link'>{l === 'en' ? `RU` : `EN`}</Link>
                    {showBmenu ? <Bmenu onClick={() => setShowBmenu(!showBmenu)} /> : null}
                </div>
            </div>
        </Wrapper>
    );
}