import React, { useEffect, useState } from 'react';
import './app.scss';
import './index.css';
import {BrowserRouter, Routes, Route, useLocation} from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import { Home } from './screens/home/home';
import { Header } from './components/header/header';
import { Footer, FooterBottom } from './components/footer/footer';
import CookieConsent, { Cookies } from "react-cookie-consent";
import { Privacy } from './screens/privacy/privacy.js';
import { NotFound } from './screens/notfound/notfound';
import { LanguageConsumer, LanguageProvider } from './context/lang';
import { WorksPage } from './screens/workspage/workspage';
import { PageWorkPrivacy } from './screens/pageworkprivacy/pageworkprivacy';

export const ScrollToTops = (props) => {
  const {pathname} = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}

function getWindowDimensions() {
  const {innerWidth: width, innerHeight: height} = window;
  return {width, height};
}

export function useWindowDimensions() {
  const [windowDimensions,
      setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
      function handleResize() {
          setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

// switch(lang) {
//   case 'ru':
//     l = 'ru';
//     break;

//   case '': 
//     l = 'en'
//     break;
// }
// const lang = window.location.pathname.replace(/[/]/g, '');


//TODO rewrite this lang take
const lang = window.location.pathname.replace(/[/]/g, '');
export let l;
if(lang.indexOf('en') >= 0) {
  l = 'en';
} else {
  l = 'ru';
}

function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <LanguageProvider>
      <ScrollToTops>
          <div style={{fontFamily: l === 'en' ? 'NeueMachina' : 'Montserrat'}} className="app">
            <Header />
            <LanguageConsumer>
            {({ updateLanguage }) => {updateLanguage(l)}}
            </LanguageConsumer>
              <Routes>
                  {l === 'en' ? <Route path="/en" element={<Home />} exact/> : <Route path="/" element={<Home />} exact/>}
                  {l === 'en' ? <Route path="/en/privacy" element={<Privacy />} exact/> : <Route path="/privacy" element={<Privacy />} exact/>}
                  <Route path="/page-work/:linkName" element={<WorksPage />} />
                  <Route path="/page-work/:linkName/privacy" element={<PageWorkPrivacy />} />
                  <Route path="*" element={<NotFound />} exact/>
              </Routes>
              <Footer />
              <FooterBottom />
              <CookieConsent
                location="bottom"
                buttonText={l === 'en' ? "Got it" : 'Согласен' }
                cookieName="myAwesomeCookieName2"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#fff", fontSize: "13px", background: "#197DBE" }}
                expires={150}
              >
                {l === 'en' ? "This website uses cookies to enhance the user experience." : 'Этот веб-сайт использует файлы cookie для улучшения взаимодействия с пользователем.' }{" "}
              </CookieConsent>
              <ScrollToTop smooth />
          </div>
      </ScrollToTops>
      </LanguageProvider>
  </BrowserRouter>
  );
}

export default App;
