import React from 'react';
import { l } from '../../app';
import { AboutUs } from '../../components/blocks/aboutus/aboutus';
import { Contact } from '../../components/blocks/contact/contact';
import { Intro } from '../../components/blocks/intro/intro';
import { OurTools } from '../../components/blocks/ourtools/ourtools';
import { OurWorks } from '../../components/blocks/ourworks/ourworks';
import { WhatWeDo } from '../../components/blocks/whatwedo/whatwedo';
import { Wrapper } from '../../components/wrapper/wrapper';
import { Helmet } from "react-helmet";
import './home.scss'

export const Home = () => {
    return (
        <div className='home'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Drivery</title>
                <link rel="canonical" href="https://drivery.org/" />
            </Helmet>
            <Wrapper>
                <Intro />
                <WhatWeDo />
            </Wrapper>
            <AboutUs />
            <Wrapper>
                <OurTools />
                {l === 'en' ? null : <OurWorks />}
            </Wrapper>
            <Contact />
        </div>
    );
}