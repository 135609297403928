import React, { useEffect, useRef, useCallback, useState } from 'react';
import './workspage.scss';
import { useParams } from "react-router-dom";
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import { Wrapper } from '../../components/wrapper/wrapper';
import { works } from '../../components/blocks/ourworks/worksdata';
import Slider from "react-slick";
import '../../components/blocks/ourworks/ourworks.scss'
import { useWindowDimensions } from '../../app';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { BreadCrumbs } from '../../components/breadcrumbs/breadcrumbs';

export const WorksPage = () => {
    const { width } = useWindowDimensions();
    const lightGallery = useRef(null);
    const [showSpoiler, setShowSpoiler] = useState(false);
    const [platform, setPlatform] = useState('');
    const [definedLink, setDefinedLink] = useState('');
    // Get title from url and check in array matches
    const worksId = useParams();

    let wId;
    works.forEach(work => {
        if(worksId.linkName === work.linkName) {
            wId = work;
        }
    });

    const linksData = [
        {
          name: 'Главная',
          to: '/',
        },
        {
            name: 'Наши работы',
            to: '/#our-works',
        },
        {
          name: wId.title,
          to: '#',
        },
    ];

    const onInit = useCallback((detail) => {
        if (detail) {
            lightGallery.current = detail.instance;
        }
    }, []);

    // Items for Gallery
    const getItems = useCallback(() => {
        return wId?.images.map((image, i) => {
          return (
            <a key={i} className='works-page__gallery-item' href={require(`../../assets/our-works/${image}`)}>
                <img className='works-page__gallery-item-img' alt="" src={require(`../../assets/our-works/${image}`)} />
            </a>
          );
        });
      }, [works]);

    var settings = {
        nextArrow: <img src={require('../../assets/our-works/right-arrow.svg').default} alt="arrow" />,
        prevArrow: <img src={require('../../assets/our-works/left-arrow.svg').default} alt="arrow" />,
        autoplay: true,
        draggable: true,
        autoplaySpeed: 3500,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: width <= 800 ? 1 : 3,
        slidesToScroll: 1
    };

    const downloadGame = () => {
        let url = wId.link;
        const fileName = url.split("/").pop();
        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    }

    const toggleSpoiler = () => {
        setShowSpoiler(prevShowSpoiler => !prevShowSpoiler);
    };

    useEffect(() => {
        const userAgent = navigator.userAgent;
        console.log(userAgent);
        if (userAgent.includes("Windows")) {
            setDefinedLink(wId.link);
            setPlatform('Windows');
          } else if (userAgent.includes("Mac")) {
            setPlatform('Mac');
            setDefinedLink('https://apps.apple.com/ru/app/%D0%BD%D0%B5%D0%B9%D1%80%D0%BE%D1%81%D0%B5%D1%82%D0%B8-gpt-%D1%87%D0%B0%D1%82-%D0%B8%D0%B7%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F/id6450005796');
          } else if (userAgent.includes("Android")) {
            setPlatform('Android');
            setDefinedLink('https://play.google.com/store/apps/details?id=com.drivery.neirosety_gpt_dalle');
          } else if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
            setPlatform('iPhone');
            setDefinedLink('https://apps.apple.com/ru/app/%D0%BD%D0%B5%D0%B9%D1%80%D0%BE%D1%81%D0%B5%D1%82%D0%B8-gpt-%D1%87%D0%B0%D1%82-%D0%B8%D0%B7%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F/id6450005796');
          } else {
            setPlatform('Unknown');
            setDefinedLink(wId.link);
          }
        lightGallery.current.refresh();
    }, [works]);

    return (
        <div className='works-page'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{wId.title}</title>
                <link rel="canonical" href={`https://drivery.org/ru/page-work/${wId.linkName}`} />
                <meta
                name="description"
                content={wId.subtitle}
                />
            </Helmet>
            <Wrapper>
                <BreadCrumbs links={linksData} />
                <div className='works-page__header'>
                    <div className='works-page__header-title-block'>
                        <img src={require(`../../assets/our-works/icons/${wId.icon}.svg`)} className="works-page__header-title-block-img" alt='icon'/>
                        <h1 className='works-page__header-title-block-title'>{wId.title}</h1>
                    </div>
                    {wId.title === 'Нейросети GPT и DALL-E' ? 
                    <a 
                    href={definedLink} 
                    onClick={ wId.title === 'Bitminer' ? downloadGame : null } 
                    target="_blank" className="button link works-page__header-link" 
                    >
                    {wId.buttonName} 
                    <img src={require('../../assets/our-works/Arrow_Right.svg').default} className='our-works__item-btn-link works-page__header-link--icon' alt='icon' /></a>
                    :
                    <a href={wId.title === 'Bitminer' ? null : wId.link} onClick={ wId.title === 'Bitminer' ? downloadGame : null } target="_blank" className="button link works-page__header-link" >{wId.buttonName} <img src={require('../../assets/our-works/Arrow_Right.svg').default} className='our-works__item-btn-link works-page__header-link--icon' alt='icon' /></a>}
                </div>
                <div className='works-page__subtitle'>{wId.subtitle}</div>
                <div className='works-page__text'>
                {wId.text.length > 300 ? (
                    <div className="works-page__spoiler" onClick={toggleSpoiler}>
                        {showSpoiler ? wId.text : `${wId.text.slice(0, 300)}... `}
                        <a style={{color: "blue", cursor: 'pointer'}}>{showSpoiler ? ' Показать меньше' : 'Показать больше'}</a>
                    </div>
                    ) : (
                    wId.text
                    )}
                </div>

                <div className='works-page__heading'>Галерея</div>
                <LightGallery
                    onInit={onInit}
                    speed={500}
                    elementClassNames="works-page__gallery"
                    plugins={[lgThumbnail]}>
                    {getItems()}
                </LightGallery>
                {wId.title == 'Нейросети GPT и DALL-E' ? <Link to={`/page-work/${wId.title}/privacy`}>Политика конфиденциальности</Link> : null}
                <div className='works-page__heading'>Другие проекты:</div>
                <>
                <Slider {...settings}>
                {works.map(slide => {
                const { id, img, icon, title, subtitle, linkName } = slide;

                return (
                    <a href={`/page-work/${linkName}`} key={id} className='our-works__item link'>
                        <img src={require(`../../assets/our-works/${img}.webp`)} className="our-works__item-img" alt='our-work_image'/>
                        <div className='our-works__item-name'>
                            <img src={require(`../../assets/our-works/icons/${icon}.svg`)} className="our-works__item-name-img" alt='icon'/>
                            <div className='our-works__item-name-title'>{title}</div>
                        </div>
                        <div className='our-works__item-subtitle'>{subtitle}</div>
                        <a href={`/page-work/${linkName}`} className='our-works__item-btn'>Подробнее <img src={require('../../assets/our-works/chevron-right.svg').default} className='our-works__item-btn-link' alt='icon' /></a>
                    </a>
                )})}
            </Slider>
            </>
            </Wrapper>
        </div>
    );
}
