import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { l } from '../../app';
import { TranslatableText } from '../../context/lang';
import './bmenu.scss';

export const Bmenu = ({onClick}) => {
    return (
        <div onClick={onClick} className='bmenu__wrapper'>
            <div className='bmenu'>
                <HashLink onClick={onClick} to={l === 'en' ? `/en/#about-us` : `/#about-us`} className='bmenu__link'>
                <TranslatableText
                dictionary={{
                    en: "About us",
                    ru: "О Нас",
                }} />
                </HashLink>
                <HashLink onClick={onClick} to={l === 'en' ? `/en/#our-tools` : `/#our-tools`} className='bmenu__link'>
                <TranslatableText
                dictionary={{
                    en: "Our tools",
                    ru: "Наши инструменты",
                }} />
                </HashLink>
                <HashLink onClick={onClick} to={l === 'en' ? `/en/#our-works` : `/#our-works`} className='bmenu__link'>
                <TranslatableText
                dictionary={{
                    en: "Our projects",
                    ru: "Наши проекты",
                }} />
                </HashLink>
                <HashLink onClick={onClick} to={l === 'en' ? `/en/#contact` : `/#contact`} className='bmenu__link'>
                <TranslatableText
                dictionary={{
                    en: "Contact",
                    ru: "Контакты",
                }} />
                </HashLink>
            </div>
        </div>
    );
}