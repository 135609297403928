import React from 'react';
import { Button } from '../../button/button';
import './intro.scss'
import { useWindowDimensions } from '../../../app';
import { l } from '../../../app';
import { TranslatableText } from '../../../context/lang';

export const Intro = () => {
    const { width } = useWindowDimensions();

    return (
        <div className='intro'>
            <div className='intro__title-block'>
                <div className='intro__title-block-title'>
                    <TranslatableText
                    dictionary={{
                        en: "Our team for",
                        ru: "Наша команда для",
                    }} />
                    <br />
                    <TranslatableText
                    dictionary={{
                        en: "your ",
                        ru: "ваших ",
                    }} />
                    <span className='intro__title-block-title--border'>
                    <TranslatableText
                        dictionary={{
                            en: "projects",
                            ru: "проектов",
                        }} />
                    </span>
                </div>
                <h1 className='intro__title-block-subtitle'>
                    {l === 'en' ? <h1 className='intro__title-block-subtitle--drivery'>Drivery</h1> : <h1 className='intro__title-block-subtitle--drivery'>Драйвери</h1> }
                <TranslatableText
                    dictionary={{
                        en: " - IT company with best team",
                        ru: " - аккредитованная IT",
                    }} /><br />
                <TranslatableText
                    dictionary={{
                        en: " of specialists that implement projects",
                        ru: " компания, собравшая лучших",
                    }} /><br />
                    <TranslatableText
                        dictionary={{
                            en: "of any complexity and has the",
                            ru: "специалистов для реализации проектов",
                        }} /> <br /> 
                    <TranslatableText
                        dictionary={{
                            en: "government accreditation.",
                            ru: "любой сложности",
                        }} />
                </h1>
                <Button link={l === 'en' ? `/en/#contact` : `/#contact`} name={l === 'en' ? `Order a project` : `Заказать проект`} styleClass="intro__btn" />
            </div>
            <div className='intro__img-block'>
                {width <= 768 ? <img className='intro__img-block-smallimg' src={require('../../../assets/images/intro-m.webp')} alt=''/> : <img className='intro__img-block-img' src={require('../../../assets/images/intro.webp')} alt=''/>}
            </div>
        </div>
    );
}