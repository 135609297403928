import React from 'react';
import { HashLink } from 'react-router-hash-link';
import './button.scss';

export const Button = ({styleClass, name, onClick, link}) => {
    return (
        <HashLink to={link} onClick={onClick} className={`button link ${styleClass}`}>
            {name}
        </HashLink>
    );
}