import React from "react";
import { render } from "react-dom";

const LanguageContext = React.createContext();
export const LanguageConsumer = LanguageContext.Consumer;

export class LanguageProvider extends React.Component {
  state = {
    language: "ru"
  };

  updateLanguage = e => this.setState({ language: e });

  render() {
    return (
      <LanguageContext.Provider
        value={{
          language: this.state.language,
          updateLanguage: this.updateLanguage
        }}
      >
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}

export const TranslatableText = props => (
    <LanguageConsumer>
      {({ language }) => props.dictionary[language]}
    </LanguageConsumer>
  );