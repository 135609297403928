import React from 'react';
import { l } from '../../../app';
import { Title } from '../../title/title';
import './whatwedo.scss'

const WhatWeDoCards = ({cards}) => {
    return (
        <div className='what-we-do__cards'>
            {cards.map((card, i) => (
                <div key={i} className='what-we-do__cards-item'>
                    <img className='what-we-do__cards-item-img' src={require(`../../../assets/icons/${card.icon}.svg`)} alt=''/>
                    <div className='what-we-do__cards-items'>
                        <div className='what-we-do__cards-item-title'>{card.title}</div>
                        <div className='what-we-do__cards-item-subtitle'>{card.subtitle}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export const WhatWeDo = () => {
    return (
        <div className='what-we-do'>
            <Title name={l === 'en' ? `What we do?` : `Чем мы занимаемся?`} />
            <WhatWeDoCards cards={[
                {
                    icon: 'smartphone',
                    title: l === 'en' ? `Application development for iOS and Android` : `Разработка приложений для iOS и Android`,
                    subtitle: l === 'en' ? `Business applications for any needs` : `Бизнес-приложения для любых нужд`,
                },
                {
                    icon: 'monitor',
                    title: l === 'en' ? `Web site and web interface development` : `Разработка веб-сайта и веб-интерфейса`,
                    subtitle: l === 'en' ? `Online stores, Web applications, single-page landing pages, Adaptive layout` : `Интернет-магазины, веб-приложения, одностраничные лендинги, адаптивная верстка`,
                },
                {
                    icon: 'robot',
                    title: l === 'en' ? `Bots development for business processes automation` : `Разработка ботов для автоматизации бизнес-процессов`,
                    subtitle: l === 'en' ? `Give away all routine` : `Откажитесь от всей этой рутины`,
                },
            ]} />
        </div>
    );
}