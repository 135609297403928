import React from 'react';
import './breadcrumbs.scss';
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

export const BreadCrumbs = ({links}) => {
    const navigate = useNavigate();

    return (
        <div className="breadcrumbs">
            <div className="breadcrumbs__back">
                <Link className='breadcrumbs__back-btn' onClick={() => navigate(-1)}>
                    <img 
                    src={require('../../assets/our-works/chevron-right.svg').default} 
                    className='breadcrumbs__back-btn-img' 
                    alt='icon' />
                </Link>
            </div>
            <div className="breadcrumbs__links">
            {links.map(link => {
                return  (
                    <HashLink className='breadcrumbs__links-item' to={link.to}>{link.name}</HashLink>
                )    
            })}
            </div>
        </div>
    );
}