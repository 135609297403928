import React from 'react'
import './wrapper.scss'

export const Wrapper = ({children, styleClass}) => {
    return (
        <div className={`wrapper ${styleClass}`}>
            {children}
        </div>
    );
}
