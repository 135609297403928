import React from 'react'
import './modal.scss';
import { l } from '../../app';

export const Modal = ({onPress}) => {
    return (
        <div class="wrap">
            <div class="modal js-modal">
                <div class="modal-image">
                    <img className='modal-image__check' src={require("../../assets/icons/check.svg").default} alt="checkIcon" />
                </div>
                <h1 className='h1'>{l === 'en' ? 'Great!' : 'Отлично!'}</h1>
                <p className='p'>{l === 'en' ? 'The form has been sent, your application will be reviewed within an hour.' : 'Форма отправлена, ваше заявление будет рассмотрено в течение часа.'}</p>
                <button onClick={onPress} class="btn js-close">{l === 'en' ? 'Close' : 'Закрыть'}</button>
            </div>
        </div>
    );
}