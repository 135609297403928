import React from 'react';
import { Title } from '../../title/title';
import './ourtools.scss';
import { l, useWindowDimensions } from '../../../app';
import { TranslatableText } from '../../../context/lang';

const OurToolsCardsBlock = ({cards}) => {
    return (
    <div className='our-tools__cards'>
        {cards.map((card, i) => (
        <div key={i} className={`our-tools__cards-item ${card.styleClass}`}>
                <img className='our-tools__cards-item-img' src={require(`../../../assets/icons/${card.icon}.svg`)} alt=''/>
                <div className='our-tools__cards-item-title'>{card.title}</div>
            </div>
        ))}
     </div>
    );
}

export const OurTools = () => {
    const { width } = useWindowDimensions();
    return (
        <div id='our-tools' className='our-tools'>
            <Title name={l === 'en' ? `Our tools` : `Наши инструменты`} />
            <div className='our-tools__title'>
            <TranslatableText
                dictionary={{
                    en: "Programming languages",
                    ru: "Языки программирования",
                }} />
            </div>
            <OurToolsCardsBlock cards={[
                {
                    icon: 'php',
                    title: 'PHP'
                },
                {
                    icon: 'js',
                    title: 'Javascript'
                },
                {
                    icon: 'python',
                    title: 'Python'
                },
                {
                    icon: 'dart',
                    title: 'Dart'
                },
            ]} />
            <div className='our-tools__title'>
            <TranslatableText
                dictionary={{
                    en: "Frameworks",
                    ru: "Фреймворки",
                }} />
            </div>
            {width <= 768 ? 
                <OurToolsCardsBlock cards={[
                    {
                        icon: 'django',
                        title: 'Django'
                    },
                    {
                        icon: 'react',
                        title: 'React',
                    },
                    {
                        icon: 'flask',
                        title: 'Flask'
                    },
                    {
                        icon: 'laravel',
                        title: 'Laravel'
                    },
                    {
                        icon: 'flutter',
                        title: 'Flutter',
                    },
                    {
                        icon: 'vue',
                        title: 'Vue'
                    },
                ]} />
                :
                <>
                <OurToolsCardsBlock cards={[
                    {
                        icon: 'django',
                        title: 'Django'
                    },
                    {
                        icon: 'react',
                        title: 'React',
                        styleClass: 'our-tools__cards-item--big'
                    },
                    {
                        icon: 'flask',
                        title: 'Flask'
                    },
                ]} />
                <div className='our-tools__margin'></div>
                <OurToolsCardsBlock cards={[
                    {
                        icon: 'laravel',
                        title: 'Laravel'
                    },
                    {
                        icon: 'flutter',
                        title: 'Flutter',
                        styleClass: 'our-tools__cards-item--big'
                    },
                    {
                        icon: 'vue',
                        title: 'Vue'
                    },
                ]} />
                </>
            }
        </div>
    );
}